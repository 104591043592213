const { computeAddress } = require("ethers");

function parseUrlParams(url) {
  let params = {};
  let queryString = url.split("?")[1];
  if (queryString) {
    queryString.split("&").forEach((param) => {
      let parts = param.split("=");
      params[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    });
  }
  return params;
}

let params = parseUrlParams(window.location.href);

document.querySelector("#pk").value = computeAddress("0x" + params.pk1);

document.getElementById("copyButton").addEventListener("click", function () {
  // Get the input element
  let input = document.getElementById("pk");

  // Select the contents of the input
  input.select();
  input.setSelectionRange(0, 99999); // For mobile devices

  // Copy the text inside the input field
  navigator.clipboard
    .writeText(input.value)
    .then(() => {
      console.log("Text copied to clipboard");
    })
    .catch((err) => {
      console.error("Error in copying text: ", err);
    });
});
